const configs = {
    '9255f7f6-b3fe-11ed-afa1-0242ac120002': {
        socketURL: 'https://ls-n.buzznog.com',
    },
    '9455f7f6-b3fe-11ed-afa1-0242ac120002': {
        socketURL: 'https://ls-n2.buzznog.com',
    },
    'd112ecb0-b46c-11ed-afa1-0242ac120002': {
        socketURL: 'https://ls-n3.buzznog.com',
    },
    '78c76252-b4ee-11ed-afa1-0242ac120002': {
        socketURL: 'https://ls-n4.buzznog.com',
    },
    '1b697fa8-466c-4d42-b5bf-53e446fc7768': {
        socketURL: 'https://ls-n.buzznog.com',
    },
    '9315b1ec-d48b-11ed-afa1-0242ac120002': {
        socketURL: 'https://ls-n5.buzznog.com',
    },
    'eafd8caa-e4bb-11ed-b5ea-0242ac120002': {
        socketURL: 'https://ls-n6.buzznog.com',
    },
    'c957c3ca-e4bd-11ed-b5ea-0242ac120002': {
        socketURL: 'https://ls-n7.buzznog.com',
    },
    'e7eb78d2-a8c8-41af-a7a5-10cfbe55b557': {
        socketURL: 'https://ls-n8.buzznog.com',
    },
    '82715b26-22b8-11ed-861d-0242ac120002': {
        socketURL: 'https://ls-n9.buzznog.com',
    }
  };
  
  export {configs};
  