import SocketIOClient from 'socket.io-client';
import * as SocketActions from './socket-actions';
import { configs } from '../config/production';

//const url = 'https://ls-n.buzznog.com';

export default class SlaveSocketsManager {
	static instance = null;

	constructor() {
		this.socket = null;
	}

	static getInstance() {
		if (SlaveSocketsManager.instance == null) {
			SlaveSocketsManager.instance = new SlaveSocketsManager();
		}
		return this.instance;
	}

	connect = (callback, name, id) => {
		const url = configs[id].socketURL;
		this.socket = SocketIOClient(url, { transports: ["websocket"] });

		this.socket.on(SocketActions.CONNECT, () => {
			callback(SocketActions.CONNECT);
		});

		this.socket.on(SocketActions.INFO, (info) => {
			if (this.socket && this.socket.connected) {
				this.socket.emit(SocketActions.SUBSCRIBE, { appId: id, username: name });
				callback(SocketActions.INFO, info);
			}
		});

		this.socket.on(SocketActions.PLAY, (payload) => 
		{
			if (payload && payload.message.id == 1 && callback) {
				callback(SocketActions.STOP, payload.message);
			}
			else if (payload && payload.message && callback) {
				callback(SocketActions.PLAY, payload.message);
			}
		});

		this.socket.on(SocketActions.STOP_CLIENT, () => {
			if (callback) {
				callback(SocketActions.STOP_CLIENT, {});
			}
		});

		this.socket.on(SocketActions.DISCONNECT, () => {
			console.log('Slave Socket Disconnected');
		});
	}

	disconnect = () => {
		if (this.socket && this.socket.connected) {
			this.socket.disconnect();
		}
	}
}
