export const CONNECT = 'connect';
export const RECONNECT = 'reconnect';
export const DISCONNECT = 'disconnect';
export const INFO = 'info';
export const SUBSCRIBE = 'subscribe';
export const PLAY = 'play';
export const STOP = 'stop';
export const USER_JOIND = 'user_joined';
export const START_LIGHT_SHOW = 'start_lightshow';
export const STOP_LIGHT_SHOW = 'stop_lightshow';
export const OVERRODE = 'override';
export const STOP_CLIENT = 'lightshow_stopped';
