import {LightShow} from './activities';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:id/:lightShowDetailsId" element={<LightShow />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
