import "../App.css";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SlaveSocketManager from "../Managers/slave-socket-manager";
// import NoSleep from 'nosleep.js';
import { Button, Form, Modal } from 'react-bootstrap';
import * as SocketActions from '../Managers/socket-actions';
import axios from 'axios';
import { Progress } from 'antd';
import Logo from '../assets/loaderImage.png';
import modalImage from '../assets/modalImage.png';
import closeIcon from '../assets/closeIcon.svg';
import afterSubmitGift from '../assets/afterSubmitGift.svg';
import backImage from '../assets/Back.png';
import yellowSign from '../assets/yellowSign.svg';
import styles from './styles.module.css';
import moment from 'moment';

function LightShow () 
{
  const [bgColor, setBgColor] = useState("#ffff");
  let { id, lightShowDetailsId } = useParams();
  const [lightShowData, setLightShowData] = useState(null);
  const [lightShowStarted, setLightShowStarted] = useState(false);
  const [lightShowEnded, setLightShowEnded] = useState(false);
  const [lightShowCountDown, setLightShowCountDown] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [progress, setProgress] = useState(20);
  const [afterSubmitModal, setAfterSubmitModal] = useState(false);
  const [modalLoader, setModalLoader] = useState(false);

  // const [circle1Selected, setCircle1Selected] = useState(false);
  // const [circle2Selected, setCircle2Selected] = useState(false);
  // const [circle3Selected, setCircle3Selected] = useState(false);
  // const [circle4Selected, setCircle4Selected] = useState(false);
  // const [sizeSelected, setSizeSelected] = useState(null);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showClaim, setShowClaim] = useState(false);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [extraInfo, setExtraInfo] = useState(null);

  const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
  
  useEffect(() => 
  {
    setShowLoading(true);
    setProgress(35);
    getLightShowBranding();
  }, []);

  useEffect(() => 
  {
    if(lightShowData && !showLoading)
    {
      checkEventStatus(lightShowData.lightShowStartDate, lightShowData.lightShowEndDate)
    }
  }, [lightShowData]);

  const trackEvent = async() =>
  {
    await axios.post(`${process.env.REACT_APP_SERVER_URL}/applications/${id}/track/${lightShowDetailsId}`);
  };

  const getLightShowBranding = async () => {
    setShowLoading(true);
    setShowError(false);

    setProgress(65);

    try {
      setProgress(75);
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/applications/${id}/LightShowBranding/${lightShowDetailsId}`);
      setLightShowData(response.data.data);
      setProgress(90);      
      if(response.data.data && !localStorage.getItem(lightShowDetailsId + 'JOINED') && response.data.data.active)
      {
        trackEvent()
        localStorage.setItem(lightShowDetailsId + 'JOINED', true)
      }
    }
    catch (error) {
      debugger
      setShowLoading(false);
      setShowError(true);
      console.error(error);
    }

    setProgress(100);
    setShowLoading(false);
  }

  const reload = () => {
    setProgress(0);
    setShowLoading(true);
    setShowError(false)
    setProgress(30);
    getLightShowBranding();
  }

  const hide = () => {
    setShowClaim(false)
    // setCircle1Selected(false);
    // setCircle2Selected(false);
    // setCircle3Selected(false);
    // setCircle4Selected(false);
    // setSizeSelected(null);
    setName(null);
    setEmail(null);
    setPhoneNumber(null);
    setExtraInfo(null);
  }

  const checkEventStatus = (startDate, endDate) => 
  {
    const now = moment(new Date(Date.now()), DATE_TIME_FORMAT).toDate().valueOf();
    const eventStartTimestamp = moment(startDate, DATE_TIME_FORMAT).valueOf();
    const eventEndTimestamp = moment(endDate, DATE_TIME_FORMAT).valueOf();

    if(!lightShowData.active){
      setShowError(true);
      setShowLoading(false);
    }

    if(now >= eventEndTimestamp)
    {
      setLightShowEnded(true);
      let data = JSON.parse(localStorage.getItem(lightShowDetailsId));

      if(data != null 
          && data.UserLightShowCompleted == false
          && data.UserLightShowRedeemed == false) 
        {
            let ob = {
              UserLightShowCompleted : true,
              UserLightShowRedeemed : false,
              UserLightShowInProgress : false
            }

            localStorage.removeItem(lightShowDetailsId);
            localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))
        }
    }
    else if(now >= eventStartTimestamp && lightShowData.active)
    {
      setLightShowStarted(true);
      let ob = 
      {
        UserLightShowCompleted : false,
        UserLightShowRedeemed : false,
        UserLightShowInProgress : true
      }

      localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))
    }
    else {
      setLightShowCountDown(true);
    }
  }
    
  const onPayloadReceived = (event, data) => {
    console.log(event, data);
    if (event === SocketActions.CONNECT) 
    {
      console.log('Connected');
    }
    else if (event === SocketActions.PLAY)
    {
      setLightShowEnded(false);
      setLightShowCountDown(false);
      setBgColor(data.c);
      setLightShowStarted(true)
      let dataItem = JSON.parse(localStorage.getItem(lightShowDetailsId));
      if(dataItem == null) 
      {
          let ob = 
          {
            UserLightShowCompleted : false,
            UserLightShowRedeemed : false,
            UserLightShowInProgress : true
          }

          localStorage.removeItem(lightShowDetailsId)
          localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))
      }
    }
    else if (event === SocketActions.STOP)
    {
      if(data.id == 1)
      {
        setLightShowStarted(false)
        setLightShowEnded(true);
        let ob = {
          UserLightShowCompleted : true,
          UserLightShowRedeemed : false,
          UserLightShowInProgress : false
        }
  
        localStorage.removeItem(lightShowDetailsId)
        localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))  
      }
    }
  };

  const showClaimFunction = () => {
    setShowClaim(true)
  };

  const calculateTimeLeft = () => {
    const now = new Date(Date.now());
    const timeLeft = moment(lightShowData.lightShowStartDate, DATE_TIME_FORMAT).valueOf() - now;
    const remainingDays = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const remainingHours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const remainingMinutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const remainingSeconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
  
    setDays(remainingDays);
    setHours(remainingHours);
    setMinutes(remainingMinutes);
    setSeconds(remainingSeconds);

    if(Math.sign(remainingDays) == -1 
        && Math.sign(remainingHours) == -1 
        && Math.sign(remainingMinutes) == -1 
        && Math.sign(remainingSeconds) == -1
        && !lightShowStarted)
    {
      setLightShowCountDown(false)
    }
    else if(!remainingDays && !remainingHours && !remainingMinutes && !remainingSeconds)
    {
      setLightShowStarted(true);
      
      let ob = {
        UserLightShowCompleted : false,
        UserLightShowRedeemed : false,
        UserLightShowInProgress : true
      }

      localStorage.removeItem(lightShowDetailsId)
      localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))
      setLightShowCountDown(false);
    }
  };
  
  const submitGiftData = () => {
    setProgress(35);
    var objectData = { name, email, phoneNumber, extraInfo };
    setModalLoader(true);
    setProgress(50);

    axios.post(`${process.env.REACT_APP_SERVER_URL}/applications/${id}/LightShowGift/${lightShowDetailsId}`, objectData)
    .then(response =>
      {
        setProgress(80);
        let data = JSON.parse(localStorage.getItem(lightShowDetailsId));
        if(response 
          && (data != null 
              && data.UserLightShowCompleted == true
              && data.UserLightShowRedeemed == false) || !data) 
        {
            let ob = {
              UserLightShowCompleted : false,
              UserLightShowRedeemed : true,
              UserLightShowInProgress : false
            }

            localStorage.removeItem(lightShowDetailsId);
            localStorage.setItem(lightShowDetailsId, JSON.stringify(ob));
        }

        setProgress(100);
        setModalLoader(false);
        setAfterSubmitModal(true);      
    })
    .catch(error =>
    {
      let data = JSON.parse(localStorage.getItem(lightShowDetailsId));
      if(error.response && error.response.data.message == "Each user is eligible to make a claim for the light show gift only once. Subsequent requests for claiming the gift will not be processed.")        {
        if(data != null)
        {
            let ob = {
              UserLightShowCompleted : false,
              UserLightShowRedeemed : true,
              UserLightShowInProgress : false
            }

            localStorage.removeItem(lightShowDetailsId);
            localStorage.setItem(lightShowDetailsId, JSON.stringify(ob))
        }
      }

      hide()
      console.error(error);
    });
  }

  // const setCircleSelected = (circleNumber) => {
  //   if(circleNumber === 1){
  //     setCircle1Selected(true);
  //     setCircle2Selected(false);
  //     setCircle3Selected(false);
  //     setCircle4Selected(false);
  //     setSizeSelected('S');
  //   }
  //   else if(circleNumber === 2){
  //     setCircle1Selected(false);
  //     setCircle2Selected(true);
  //     setCircle3Selected(false);
  //     setCircle4Selected(false);
  //     setSizeSelected('M');
  //   }
  //   else if(circleNumber === 3){
  //     setCircle1Selected(false);
  //     setCircle2Selected(false);
  //     setCircle3Selected(true);
  //     setCircle4Selected(false);
  //     setSizeSelected('L');
  //   }
  //   else if(circleNumber === 4){
  //     setCircle1Selected(false);
  //     setCircle2Selected(false);
  //     setCircle3Selected(false);
  //     setCircle4Selected(true);
  //     setSizeSelected('XL+');
  //   }
  // }

  useEffect(() => {
    if(lightShowData)
    {
      calculateTimeLeft();
      const timer = setInterval(calculateTimeLeft, 1000);
      return () => clearInterval(timer);
    }
  }, [lightShowData]);

  useEffect(() => {
    SlaveSocketManager.getInstance().connect(onPayloadReceived, "buzznog", id); 
  }, []);

  return (
    !showLoading ? 
    ((lightShowData && !showError) ?
      (<div>
      {
        lightShowStarted && lightShowData.active == 1
          &&
          <div className="App" id="modalId"
            ata-backdrop="static" data-keyboard="false"
            style={{
              backgroundColor: bgColor,
              transition: "all .5s ease",
              WebkitTransition: "all .5s ease",
              MozTransition: "all .5s ease",
            }}>
            <img src={lightShowData && lightShowData.imageUrl ? lightShowData.imageUrl : Logo} className={styles.overlayImage}/>
          </div>
        }
        { 
          lightShowEnded
          && 
          <div>
            <div className={styles.redColorBox}>
              <div className={styles.lightShowFinishTitleStyle}>Lightshow has ended!</div>
            </div>
            <div style={{backgroundImage: `url(${lightShowData && lightShowData.backgroundImage? lightShowData.backgroundImage : backImage})`, position: 'fixed',
              top: '75px'}} className="App">
              <div style={{display: "flex", marginTop: "0px"}}>
                <div className={styles.titleStyleFinish}> {lightShowData.title} </div>
              </div>
              {
                lightShowData.hasReward &&
                (localStorage.getItem(lightShowDetailsId) == null || 
                 JSON.parse(localStorage.getItem(lightShowDetailsId)).UserLightShowCompleted == true 
                )? 
                <div onClick={showClaimFunction} className={styles.gifClaimButtonStyle} style={{backgroundColor : lightShowData && lightShowData.color ? lightShowData.color : '#000'}}>
                  <div className={styles.gifClaimTextStyle}>
                    Claim your gift
                  </div>
                </div>
                : <div></div>
              }
              <div>
                { 
                  lightShowData.hasReward ? 
                  <div className={styles.rewardTextStyle} style={{marginTop : "22%"}}>
                    Brought to you by
                  </div>
                  : <div></div>
                }
              </div>

              <div className={styles.bottomImage}>
                  {lightShowData && lightShowData.bottomImage && <img src={lightShowData.bottomImage} width={240} height={100} alt="company image"/>}
                  <div className={styles.copyRightStyle}>
                    Powered by Buzznog
                  </div>
              </div>
            </div>

            <Modal style={{
                            backgroundColor: 'transparent',
                            opacity: '0.0 - 1.0',
                            width : '90%', 
                            height : '90%',
                            position: 'absolute', /* Position the modal within the trigger div */
                            top: '50%', /* Adjust vertical position as needed */
                            left: '50%', /* Adjust horizontal position as needed */
                            transform: 'translate(-50%, -50%)', /* Center the modal within the trigger div */
                          }} centered show={showClaim} load>
              <Modal.Body style={{
                  opacity: '0.0 - 1.0',
                  borderRadius: '20px',
                  width : '100%', 
                  height : '90%',
                  padding: '3px',
                  backgroundColor: 'transparent'
                }}>
                  {
                    !afterSubmitModal && !modalLoader 
                    ? <div className={styles.modalGiftStyle}>
                        <div style={{ backgroundImage:`url(${lightShowData && lightShowData.giftModalImageUrl ? lightShowData.giftModalImageUrl : modalImage})`}} className={styles.modalImage}>
                          <img src={closeIcon} className={styles.closeIcon} onClick={hide} alt=""/>
                        </div>
                          <Form onSubmit={submitGiftData}>
                            <Form.Group className={`${styles.formGiftInputStyle} mt-5`} controlId="formBasicNAME">
                              <Form.Control 
                                required 
                                onChange={(e) => setName(e.target.value)} 
                                style={{ boxShadow: "none", border: "none", paddingLeft: "0px"  }} 
                                type="text" placeholder="ENTER YOUR NAME" />
                            </Form.Group>
                            <Form.Group className={styles.formGiftInputStyle} controlId="formBasicEmail">
                              <Form.Control 
                                required 
                                onChange={(e) => setEmail(e.target.value)} 
                                style={{ boxShadow: "none", border: "none", paddingLeft: "0px" }}
                                type="email" 
                                placeholder="ENTER YOUR EMAIL" />
                            </Form.Group>
                            <Form.Group className={styles.formGiftInputStyle} controlId="formBasicPhoneNumber">
                              <Form.Control 
                                required 
                                onChange={(e) => setPhoneNumber(e.target.value)} 
                                style={{ boxShadow: "none", border: "none", paddingLeft: "0px"  }} 
                                type="text" 
                                placeholder="ENTER PHONE NUMBER" />
                            </Form.Group>

                            { lightShowData.giftInfoLabel &&
                              <Form.Group className={styles.formGiftInputStyle} controlId="formBasicZip">
                                <Form.Control 
                                  required 
                                  onChange={(e) => setExtraInfo(e.target.value)} 
                                  style={{ boxShadow: "none", border: "none", paddingLeft: "0px"  }} 
                                  type="text" 
                                  placeholder= {lightShowData.giftInfoLabel} />
                              </Form.Group>}

                            {/* <div className={styles.size}>
                              <div className={styles.sizeTextStyle}>
                                <div className={styles.sizeTextStyle}>Size</div>
                              </div>
                              <div className={styles.circleSizeStyle}>
                                <div onClick={() => setCircleSelected(1)} className={!circle1Selected ? styles.sizeCircleTextStyle : styles.sizeCircleTextSelectedStyle}>S</div>
                              </div>
                              <div className={styles.circleSizeStyle}>
                                <div onClick={() => setCircleSelected(2)} className={!circle2Selected ? styles.sizeCircleTextStyle : styles.sizeCircleTextSelectedStyle}>M</div>
                              </div>
                              <div className={styles.circleSizeStyle}>
                                <div onClick={() => setCircleSelected(3)} className={!circle3Selected ? styles.sizeCircleTextStyle : styles.sizeCircleTextSelectedStyle}>L</div>
                              </div>
                              <div className={styles.circleSizeStyle}>
                                <div onClick={() => setCircleSelected(4)} className={!circle4Selected ? styles.sizeCircleTextStyle : styles.sizeCircleTextSelectedStyle}>XL+</div>
                              </div>
                            </div> */}
                            <Button 
                              type="submit"
                              className={`${styles.submitGiftButton}`}
                              style={{backgroundColor : lightShowData && lightShowData.color ? lightShowData.color : '#000'}}
                              disabled= {!email || !name || !phoneNumber || (lightShowData.giftInfoLabel ? !extraInfo : true)}>
                              Submit
                            </Button>
                          </Form>
                      </div>
                    :
                    (afterSubmitModal && !modalLoader 
                    ?
                    (<div className={styles.modalGiftStyle2}>
                      <div style={{marginTop : '20%'}}>
                        <img src={afterSubmitGift} width={98} height={93}/>
                      </div>

                      <div style={{display : 'flex', flexDirection : 'column'}}>
                        <span className={styles.congratsTitleStyle}>Congrats!</span> 
                        <span className={styles.congratsSubTitleStyle}>Your Gift is on it’s way!</span>
                      </div>

                      <div onClick={hide} className={styles.gifClaimButtonStyle2} style={{backgroundColor : lightShowData && lightShowData.color ? lightShowData.color : '#000'}}>
                        <div className={styles.afterGifClaimTextStyle}>
                          back to light show
                        </div>
                      </div>
                    </div>)
                    :
                    (modalLoader &&
                    <div className={styles.modalGiftStyle2}>
                      <div style={{width: "90%", marginLeft:'6%', marginTop: '10%'}}>
                        <div style={{marginTop: '15%'}}>
                          <img src={Logo} width={90} height={25} alt="company image"/>
                        </div>
                        <div style={{ display: 'flex', alignItems:'center', rowGap: 5 }}>
                          <Progress percent={progress} status="active" strokeColor={{ from: '#ff004f', to: '#fa5284' }} />
                        </div>
                      </div>
                    </div>))
                  }
              </Modal.Body>
            </Modal>
          </div>
        }
        {
          lightShowCountDown 
          && !lightShowStarted
          &&
            <div style={{backgroundImage: `url(${lightShowData && lightShowData.backgroundImage? lightShowData.backgroundImage : backImage})`}} className="App">
                <div className={styles.titleStyle}>
                  {lightShowData.title}
                </div>
                <div >
                  <div className={styles.timeRemainingStyle}> 
                    <span style={{display : 'flex', flexDirection : 'column'}}>TIME REMAINING </span>
                      {
                        days > 0 && 
                        (<span style={{fontSize : '15px', color : '#000'}}>
                          {days > 1 ? days + ' days' : days + ' day' } and
                        </span>)
                      }
                  </div>

                  <div style={{display: "flex", justifyContent: "center", margin: "auto", marginTop: '1px'}}>  
                    <h1 className={styles.timerStyle}>{hours < 10 ? '0' + hours : hours}: </h1> 
                    <h1 className={styles.timerStyle}>{minutes < 10 ? '0' + minutes: minutes}: </h1> 
                    <h1 className={styles.timerStyle}>{seconds < 10 ? '0' + seconds : seconds} </h1>
                  </div>
                  <div>
                    { 
                      lightShowData.hasReward ?
                      <div className={styles.rewardTextStyle} >
                        {lightShowData.rewardText} 
                      </div>
                      : <div></div>
                    }
                  </div>
                </div>

                <div className={styles.bottomImage}>
                    {lightShowData && lightShowData.bottomImage && <img src={lightShowData.bottomImage} width={240} height={100} alt="company image"/>}
                    <div className={styles.copyRightStyle}>
                      Powered by Buzznog
                    </div>
                </div>
              </div> 
        }
      </div>)
    :
    (
      showError && !showLoading && 
        <div className="App" style={{backgroundImage: `url(${lightShowData && lightShowData.backgroundImage? lightShowData.backgroundImage : backImage})`}}>
          <div style={{display: "flex"}}>
            <div className={styles.titleStyle}></div>
          </div>

          <img src={yellowSign} className={styles.cautionImage} alt=""/>
          <div className={styles.tryAgainStyle}>
            let’s try that again
          </div>
            <div onClick={reload} className={styles.reloadLightShowButtonStyle}>
              <div className={styles.gifClaimTextStyle}>
                Reload the light show
              </div>
            </div>
          <div className={styles.bottomImage}>
              <div className={styles.copyRightStyle}>
                Powered by Buzznog
              </div>
          </div>
        </div>
    ))
    :
    (      
      <div className="App">
          <div style={{width: "90%", marginLeft:'6%', marginTop: '30%'}}>
          <div className={styles.loaderMargin}>
              <img src={Logo} width={90} height={25} alt="company image"/>
            </div>
            <div style={{ display: 'flex', alignItems:'center', rowGap: 5 }}>
              <Progress percent={progress} status="active" strokeColor={{ from: '#ff004f', to: '#fa5284' }} />
            </div>
          </div>
      </div>
    )
  );
}

export { LightShow };